import https from "./https"
import store from "@/store"

const organization = store.state.general.organization

const badge = {
  getBadges(params) {
    return https.get('admin/organizations/' + organization.id + '/badges', {params})
  },
  getBadge(id) {
    return https.get('admin/organizations/' + organization.id + '/badges/' + id)
  },
  updateBadge(id, params) {
    return https.put('admin/organizations/' + organization.id + '/badges/' + id, params)
  },
  createBadge(params) {
    return https.post('admin/organizations/' + organization.id + '/badges/', params)
  },
  removeBadge(id) {
    return https.delete(`admin/organizations/${organization.id}/badges/${id}`)
  },
  uploadImageByBase64(image) {
    return https.post(`admin/organizations/${organization.id}/badges/upload-image-by-base64`, {image})
  },
  getCustomers(id, params) {
    return https.get(`admin/organizations/${organization.id}/badges/${id}/customers`, { params })
  },
  bindCustomer(id, customerId) {
    return https.post(`admin/organizations/${organization.id}/badges/${id}/bind-customer`, {
      customer_id: customerId,
    })
  },
  unbindCustomers(id, customerIds) {
    return https.post(`admin/organizations/${organization.id}/badges/${id}/unbind-customers`, {
      customer_ids: customerIds,
    })
  },
}

export default badge
