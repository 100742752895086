<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>獎勵應用中心</b-breadcrumb-item>
            <b-breadcrumb-item active>徽章管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <div class="col-12 d-flex col-xl-6 mb-1 justify-content-between">
            <h4 class="font-weight-bold">徽章管理</h4>
            <b-button
              class="flex-shrink-0 flex-shrink-0 mb-2 mt-xl-1"
              variant="primary"
              :to="{ name: 'BadgeCreate' }"
              ><i class="fa fa-plus" aria-hidden="true"></i>新增徽章
            </b-button>
          </div>
          <div
            class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-end align-items-xl-center
            "
          >
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="名稱"
                v-model="query.keyword"
                v-on:keyup.enter="handlePageReset"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="handlePageReset"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table
          responsive
          striped
          hover
          :items="badges"
          :fields="fields"
          @sort-changed="handleSort"
        >
          <template #cell(image_url)="data">
            <b-img-lazy
              v-if="data.item.image_url"
              class="thumb-image"
              :src="data.item.image_url"
              alt="徽章圖片"
              width="50"
              height="50"
            />
          </template>
          <template #cell(started_at_and_end_at)="data">
            <div class="mb-1">
              {{ formatDate(data.item.available_start_at) }}
            </div>
            <div>{{ formatDate(data.item.available_end_at) }}</div>
          </template>
          <template #cell(branch)="data">
            <div class="mb-1">
              {{ data.item.branch ? data.item.branch.name : "" }}
            </div>
            <div>
              {{ data.item.branch ? data.item.branch.branch_code : "" }}
            </div>
          </template>
          <template #cell(arrange)="data">
            <b-button
              class="ml-2"
              variant="inverse-primary"
              v-if="checkPermission([consts.BADGE_LIST_VIEW])"
              :to="{
                name: 'BadgeDetail',
                params: {
                  badgeId: data.item.id,
                },
              }"
            >
              <span class="mdi mdi-eye"></span> 查看
            </b-button>
            <b-button
              class="ml-2"
              variant="inverse-warning"
              v-if="checkPermission([consts.BADGE_EDIT])"
              :to="{
                name: 'BadgeEdit',
                params: {
                  badgeId: data.item.id,
                },
              }"
            >
              <span class="mdi mdi-lead-pencil"></span> 編輯
            </b-button>
            <b-button
              class="ml-2"
              variant="inverse-danger"
              @click="deleteBadge(data.item)"
            >
              <span class="mdi mdi-delete"></span> 刪除
            </b-button>
            <b-button
              class="ml-2"
              variant="inverse-primary"
              v-if="checkPermission([consts.BADGE_EDIT])"
              :to="{
                name: 'BadgeCustomerList',
                params: {
                  badgeId: data.item.id,
                },
              }"
              >會員列表
            </b-button>
          </template>
        </b-table>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import badgeApi from "@/apis/badge";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";

export default {
  components: { CustomPagination },
  data() {
    return {
      consts,
      showLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 15,
      fields: [
        {
          key: "name",
          label: "徽章名稱",
        },
        {
          key: "image_url",
          label: "徽章圖片",
        },
        {
          key: "description",
          label: "徽章描述",
        },
        {
          key: "sort",
          label: "排序",
          sortable: true,
        },
        {
          key: "is_enabled",
          label: "狀態",
          formatter: (value) => {
            return value ? "啟用" : "停用";
          },
        },
        {
          key: "arrange",
          label: "管理",
        },
      ],
      badges: [],
      permissionChecker: new PermissionChecker(),
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        sort_by: "sort",
        order_by: "asc",
        keyword: null,
      },
    };
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchBadges();
    },
  },
  computed: {
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  mounted() {
    this.fetchBadges();
    this.initialized = true;
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  methods: {
    async fetchBadges() {
      this.showLoading = true;

      try {
        const params = {
          ...this.query,
        };

        const { data } = await badgeApi.getBadges(params);

        this.badges = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.error(error);
        alert("取得徽章資料錯誤");
      }
      this.showLoading = false;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    async deleteBadge(badge) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await badgeApi.removeBadge(badge.id);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 ",
              });
              this.fetchBadges();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
